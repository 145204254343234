import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import styled from '@emotion/styled';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Hero } from '../components/Hero';
import { device, colors, GlobalStyles } from '../components/layout/GlobalStyles';
import { BluePanels } from '../components/Blue-Panels';
import { Seo } from '../components/seo';

const links = [];

const StyledTextMain = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
        margin: 4px 0px;
    }
    div {
        align-self: center;
    }
    ul {
        margin-left: 25px;
    }
    p,
    ul {
        align-self: start;
    }
    a {
        color: ${colors.blue2};
        font-weight: 700;
    }
    .name {
    }
    span {
        color: ${colors.blue2};
        font-weight: 700;
    }
    .small-image {
        box-shadow: rgba(100, 100, 111, 0.7) 0px 7px 29px 0px;
        margin: 32px 0px;
        border-radius: 15px;
    }
    @media ${device.laptop} {
        align-items: start;
        .small-image {
            width: 680px;
            margin: 72px 0px;
        }
    }
`;

const Therapies = ({ data }) => {
    // console.log(data.allMarkdown.frontmatter.images[0]);

    let breadCrumbNames = ['Therapies', data.allMarkdown.frontmatter.title];
    let breadCrumbUrls = ['/therapies/', data.allMarkdown.frontmatter.slug];
    return (
        <>
            <Seo
                title={data.allMarkdown.frontmatter.meta.title}
                description={data.allMarkdown.frontmatter.meta.description}
            />
            <GlobalStyles />
            <Hero
                title={data.allMarkdown.frontmatter.title}
                breadCrumbUrls={breadCrumbUrls}
                breadCrumbNames={breadCrumbNames}
            ></Hero>
            <StyledTextMain className="container">
                <MDXRenderer images={data.allMarkdown.frontmatter.images}>
                    {data.allMarkdown.body}
                </MDXRenderer>
            </StyledTextMain>
        </>
    );
};

export const query = graphql`
    query ($id: String!) {
        allMarkdown: mdx(id: { eq: $id }) {
            frontmatter {
                meta {
                    title
                    description
                }
                title
                slug
            }
            body
        }
    }
`;

Therapies.Layout = DefaultLayout;

export default Therapies;
